export const dashboardRoutes = {
  applications: {
    index: '/applications',
    individuals:
      '/applications/individuals?market=&status=ManualReview%2CDeclined%2CManualDeclined%2CApproved%2CManualApproved&page=1',
    organizations:
      '/applications/organizations?market=&status=ManualReview%2CDeclined%2CManualDeclined%2CApproved%2CManualApproved&page=1',
  },
  workflows: {
    index: '/workflows',
    single: (id?: string) => `/workflows/${id}`,
  },
  entities: {
    individuals: '/entities/individuals',
    organizations: '/entities/organizations',
  },
  riskScore: {
    index: '/risk-score',
    single: (id?: string) => `/risk-score/${id}`,
  },
  theming: '/theming',
  developer: '/developer',
  monitoring: '/monitoring',
  workspace: '/workspace',
  cases: {
    single: (id?: string) => `/workspace/cases/${id}`,
  },
} as const;
